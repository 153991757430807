import React from "react";

const ErrorPage = () => {
  return (
    <div className="h-screen bg-white flex items-center justify-center">
      <h1 className="text-red-600 md:text-[120px] text-[70px] font-[900]">
        404 Page Not Found
      </h1>
    </div>
  );
};

export default ErrorPage;
